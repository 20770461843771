import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PlusIcon} from "primereact/icons/plus";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {orderDelete, orderPrint, ordersGetList, orderUpdate} from "../../services/OrderService";
import {Link} from "react-router-dom";
import {Tag} from "primereact/tag";
import {SplitButton} from "primereact/splitbutton";
import {GrPrint} from "react-icons/gr";
import {MdOutlineLocalShipping} from "react-icons/md";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {FilterMatchMode} from "primereact/api";
import {useTranslation} from "react-i18next";

export default function OrderList(props) {

    const toast = useRef(null);
    const searchQueryField = useRef(null);

    const getSearchQueryFromLocalStorage = () => {
        const searchQueryFromLS = localStorage.getItem("orderListSearchQuery")
        if(searchQueryFromLS === "" || searchQueryFromLS == null){
            return "";
        }

        return searchQueryFromLS;
    }

    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState(getSearchQueryFromLocalStorage);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("id,desc");
    const [size, setSize] = useState(50);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        paid: {value: null, matchMode: FilterMatchMode.EQUALS}
    });
    const {t, i18n} = useTranslation();

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const ordersPage = await ordersGetList(page, size, sort, searchQuery, filters);
            setOrders(ordersPage.content);
            setTotalRecords(ordersPage.totalElements);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while loading orders'),
                life: 3000,
                position: "bottom",
            });
            console.log(error);
        } finally {
            setLoading(false); // Always set loading to false
        }
    };

    useEffect(() => {
        fetchOrders();
        searchQueryField.current.value = getSearchQueryFromLocalStorage();
    }, [page, sort, size, searchQuery, filters]);

    useEffect(() => {
        initFilters();
    }, []);

    const initFilters = () => {
        setFilters({
            paid: {value: null, matchMode: FilterMatchMode.EQUALS}
        });
    };

    const deleteOrder = async (order) => {
        setLoading(true)
        try {
            await orderDelete(order.id);
            setLoading(false)
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while deleting order'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Deleted'),
            detail: t('Order deleted successfully'),
            life: 3000
        });
        fetchOrders()
    }

    const markOrderAsPaid = async (order) => {
        setLoading(true)
        try {
            const orderUpdated = order;
            orderUpdated.paid = true;
            await orderUpdate(order.id, orderUpdated);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Order updated successfully'),
            life: 3000
        });
        fetchOrders()
    }

    const markOrderAsCompleted = async (order) => {
        setLoading(true)
        try {
            const orderUpdated = order;
            orderUpdated.completed = true;
            await orderUpdate(order.id, orderUpdated);
            setLoading(false)
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Order updated successfully'),
            life: 3000
        });
        fetchOrders()
    }

    const rePrintOrder = async (order) => {
        setLoading(true)
        try {
            await orderPrint(order.id);
            setLoading(false)
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Order updated successfully'),
            life: 3000
        });
        fetchOrders()
    }

    const closeModal = () => {

    }

    const confirmDelete = (order) => {
        confirmDialog({
            message: t("Are you sure that you want to delete this order?"),
            header: t('Order Delete'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t('Yes'),
            rejectLabel: t('No'),
            accept: () => {
                deleteOrder(order)
            },
            reject: () => {
                closeModal()
            }
        });
    }

    const getHeader = () => {
        return <div className={"flex items-center justify-between w-full"}>
            <span>{t("Orders")}</span>
            <div className={"flex gap-4"}>
                <span className="p-input-icon-right">
                    {searchQuery == null ? <i className="pi pi-search"/> :
                        <i className="pi pi-times cursor-pointer" onClick={() => {
                            setSearchQuery(null)
                            searchQueryField.current.value = null;
                            localStorage.setItem("orderListSearchQuery", "")
                        }}/>}
                    <InputText placeholder={t("Search...")} ref={searchQueryField} onChange={(e) => {
                        setSearchQuery(e.target.value)
                        localStorage.setItem("orderListSearchQuery", e.target.value)
                    }}/>
                </span>
                <Link to={"/order/create"}>
                    <Button icon={<PlusIcon color={"white"} className={"mr-2"}/>} label={t("New Order")}/>
                </Link>
            </div>
        </div>
    }

    const getActionsButtons = (order) => {
        return <>
            <Link to={`/order/${order.id}/edit`}>
                <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={t("Edit Order")}
                        tooltipOptions={{position: 'bottom'}} icon={<AiFillEdit color={"grey"}/>}
                />
            </Link>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        confirmDelete(order)
                    }}
                    tooltip={t("Delete Order")} icon={<TrashIcon color={"grey"}/>}
            />
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        rePrintOrder(order)
                    }}
                    tooltip={t("Reprint Order")} icon={<GrPrint color={"grey"}/>}
            />
            <Link to={`/order/${order.id}/shipping-note`}>
                <Button className={"bg-transparent border-0 p-1 w-auto"} icon={<MdOutlineLocalShipping color={"grey"}/>}
                        tooltipOptions={{position: 'bottom'}} tooltip={t("Print Order Shippping Note")}

                />
            </Link>
        </>
    }

    const onSort = (e) => {
        setSort(`${e.sortField},${e.sortOrder === 1 ? "desc" : "asc"}`)
    }

    const onPage = (e) => {
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const confirmAction = (message, header, accept, reject = closeModal) => {
        confirmDialog({
            message: message,
            header: header,
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: accept,
            reject: reject
        });
    }

    const bulkOptions = [
        {
            label: t("Delete"),
            icon: 'pi pi-times',
            command: () => {
                confirmAction(
                    t("Are you sure you'd like to delete the selected orders?"),
                    t("Delete selected orders"),
                    () => {
                        selectedOrders.map((order) => {
                            deleteOrder(order)
                        })
                        setSelectedOrders([])
                    }
                )
            }
        },
        {
            label: t("Mark as paid"),
            icon: 'pi pi-euro',
            command: () => {
                confirmAction(
                    t("Are you sure you'd like to mark the selected orders as paid?"),
                    t("Update Orders"),
                    () => {
                        selectedOrders.map((order) => {
                            markOrderAsPaid(order)
                        })
                        setSelectedOrders([])
                    }
                )
            }
        },
        {
            label: t("Mark as completed"),
            icon: 'pi pi-check',
            command: () => {
                confirmAction(
                    t("Are you sure you'd like to mark the selected orders as completed?"),
                    t("Update Orders"),
                    () => {
                        selectedOrders.map((order) => {
                            markOrderAsCompleted(order)
                        })
                        setSelectedOrders([])
                    }
                )
            }
        },
    ]

    const getFooter = (e) => {
        return <div className={"flex gap-4"}>
            {showBulkOptions()}
            {showExportForm()}
        </div>
    }

    const showBulkOptions = () => {
        return <SplitButton
            outlined
            disabled={selectedOrders.length === 0}
            label={t("Delete Orders")}
            icon="pi pi-times"
            size={"small"}
            model={bulkOptions}
            onClick={() => {
                confirmAction(
                    t("Are you sure that you want to delete the selected orders?"),
                    t('Delete Selected Orders'),
                    () => {
                        selectedOrders.map((order) => {
                            deleteOrder(order)
                        })
                        setSelectedOrders([])
                    }
                )
            }}
        />
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_Export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const exportToExcel = () => {
        import('xlsx').then((xlsx) => {
            const ordersWithFlattenedCustomer = orders.map(order => ({
                ΗΜΕΡΟΜΗΝΙΑ: order.dateAdd,
                ID: `#${order.id}`,
                ΠΕΛΑΤΗΣ: `${order.customer.name}`,
                ΠΑΡΑΓΓΕΛΙΑ: order.description,
                ΕΤΑΙΡΕΙΑ: order.customer.companyName,
                ΔΙΕΥΘΥΝΣΗ: order.customer.address,
                ΑΦΜ: order.customer.vatNumber,
                ΤΗΛΕΦΩΝΟ: order.customer.phoneNumber1,
                ΔΟΥ: order.customer.doy,
                ΤΚ: order.customer.postCode,
                ΠΟΛΗ: order.customer.city,
                ΠΟΣΟ_ΠΑΡΑΓΓΕΛΙΑΣ: order.price,
                ΕΞΟΦΛΗΘΗ: order.paid ? "ΝΑΙ" : "OXI",
                ΚΑΤΑΣΚΕΥΑΣΤΗΚΕ: order.completed ? "ΝΑΙ" : "OXI",
            }));

            const worksheet = xlsx.utils.json_to_sheet(ordersWithFlattenedCustomer);
            const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, t('Orders'));
        });
    }

    const exportAllOrdersToExcel = async () => {
        try {
            const ordersPage = await ordersGetList(0, 99999, sort, searchQuery, filters);

            import('xlsx').then((xlsx) => {
                const ordersWithFlattenedCustomer = ordersPage.content.map(order => ({
                    ΗΜΕΡΟΜΗΝΙΑ: order.dateAdd,
                    ID: `#${order.id}`,
                    ΠΕΛΑΤΗΣ: `${order.customer.name}`,
                    ΠΑΡΑΓΓΕΛΙΑ: order.description,
                    ΕΤΑΙΡΕΙΑ: order.customer.companyName,
                    ΔΙΕΥΘΥΝΣΗ: order.customer.address,
                    ΑΦΜ: order.customer.vatNumber,
                    ΤΗΛΕΦΩΝΟ: order.customer.phoneNumber1,
                    ΔΟΥ: order.customer.doy,
                    ΤΚ: order.customer.postCode,
                    ΠΟΛΗ: order.customer.city,
                    ΠΟΣΟ_ΠΑΡΑΓΓΕΛΙΑΣ: order.price,
                    ΕΞΟΦΛΗΘΗ: order.paid ? "ΝΑΙ" : "OXI",
                    ΚΑΤΑΣΚΕΥΑΣΤΗΚΕ: order.completed ? "ΝΑΙ" : "OXI",
                }));

                const worksheet = xlsx.utils.json_to_sheet(ordersWithFlattenedCustomer);
                const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
                const excelBuffer = xlsx.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array'
                });

                saveAsExcelFile(excelBuffer, t('Orders'));
            });

        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while loading orders'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false);
        }
    }

    const exportOptions = [
        {
            label: t('Only this page'),
            icon: 'pi pi-file-excel',
            command: () => {
                exportToExcel()
            }
        },
        {
            label: t('All pages'),
            icon: 'pi pi-file-excel',
            command: () => {
                exportAllOrdersToExcel()
            }
        },
    ]
    const showExportForm = () => {
        return <SplitButton
            outlined
            label={t('Export to excel')}
            icon="pi pi-file-excel"
            size={"small"}
            model={exportOptions}
            onClick={exportToExcel}
        />
    }

    const onFilter = (params) => {
        setFilters(params.filters)
    }

    const paidFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center justify-between">
                <label htmlFor="verified-filter" className="font-bold">
                    {t("Paid")}
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value}
                                  onChange={(e) => options.filterCallback(e.value)}/>
            </div>
        );
    };

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>
        <Panel header={getHeader()}>
            <DataTable
                value={orders} showGridlines selectionMode={'checkbox'} selection={selectedOrders}
                onSelectionChange={(e) => setSelectedOrders(e.value)}
                stripedRows className={"w-full"} size={"small"} paginator
                totalRecords={totalRecords} lazy loading={loading} onSort={onSort}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Σύνολο: {totalRecords} Παραγγελίες"
                first={first} onPage={onPage} rows={size} rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
                emptyMessage={t("No orders found")}
                dataKey="id" paginatorLeft={getFooter()}
                filters={filters}
                globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                onFilter={onFilter}
            >
                <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                <Column field="dateAdd" header={t("Date")} body={(order) => {
                    if (order.dateAdd == null) {
                        return " "
                    }
                    return order.dateAdd.replace("T", " ")
                }}/>
                <Column field="id" sortable header="#" body={(order) => {
                    return <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={order.description}
                                   tooltipOptions={{position: 'bottom'}}>
                        <Tag severity="success" value={`#${order.id}`}> </Tag>
                    </Button>
                }}/>
                <Column field="customer.name" sortable header={t("Customer Name")}/>
                <Column field="customer.companyName" sortable header={t("Customer Company")} body={(order) => {
                    return `${order.customer.companyName} (ΑΦΜ: ${order.customer.vatNumber})`
                }}/>
                <Column field="customer.phoneNumber1" sortable header={t("Telephone")}/>
                <Column field="price" header={t("Amount")} body={(order) => {
                    if (order.price == null) {
                        return ""
                    }
                    return <b>{order.price.toFixed(2) + "€"}</b>
                }}/>
                <Column field="completed" header={t("Completed")} body={(order) => {
                    return order.completed ?
                        <Tag className="mr-2" icon="pi pi-check" severity="success" value={t("Completed")}/> : ""
                }}/>
                <Column field="paid" header={t("Paid")} dataType="boolean" bodyClassName="text-center"
                        style={{minWidth: '8rem'}} body={(order) => {
                    return order.paid ?
                        <Tag className="mr-2" icon="pi pi-check" severity="success" value={t("Paid")}/> : ""
                }} filter filterElement={paidFilterTemplate}/>
                <Column field="actions" header={t("Actions")} body={getActionsButtons}/>
            </DataTable>
        </Panel>
    </>
}
