import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {partnerGetDetails, partnerUpdate} from "../../services/PartnerService";
import {useTranslation} from "react-i18next";
import {technicianGetDetails, technicianUpdate} from "../../services/TechnicianService";

export default function TechnicianEdit(props) {

    const {id} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPartner = async () => {
            try {
                const partnerData = await technicianGetDetails(id);
                setName(partnerData.name);
                setPhoneNumber(partnerData.phoneNumber);
            } catch (error) {
                alert('Failed to fetch partner data:', error);
            }
        };

        fetchPartner();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await technicianUpdate(id, {
                "name": name, "phoneNumber": phoneNumber
            });
        } catch (error) {
            alert("error");
            setLoading(false)
            return
        }

        setLoading(false)
        navigate("/technician/list")
    }

    return <Panel header={t("Edit Partner")}>
        <form onSubmit={formSubmit}>
            <div className={"flex gap-4"}>
                <FormControl label={t("Name")} required={true}>
                    <InputText placeholder={t("Name")} name={"name"} value={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                               }}/>
                </FormControl>
                <FormControl label={t("Telephone")}>
                    <InputText placeholder={t("Telephone")} name={"telephone"} value={phoneNumber}
                               onChange={(e) => {
                                   setPhoneNumber(e.target.value)
                               }}/>
                </FormControl>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Update Technician")} loading={loading}/>
            </div>
        </form>
    </Panel>;
}
