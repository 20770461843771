import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";

export const storeConfigurationUpdate = async (storeConfiguration) => {
    try {
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/store-configurations/${storeConfiguration.id}/`,
            data: storeConfiguration
        });
        return response
    } catch (error) {
        throw error;
    }
};
