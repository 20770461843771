import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";

export async function statisticsGetNewRepairs(days) {
    try {
        return await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/statistics/new-repairs?days=${days}`,
        })
    } catch (error) {
        throw error;
    }
}

export async function statisticsGetRevenue(days) {
    try {
        return await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/statistics/revenue?days=${days}`,
        })
    } catch (error) {
        throw error;
    }
}

export async function statisticsGetProfit(days) {
    try {
        return await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/statistics/profit?days=${days}`,
        })
    } catch (error) {
        throw error;
    }
}

export async function statisticsGetRepairsInPartner() {
    try {
        return await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/statistics/repairs-in-partner`,
        })
    } catch (error) {
        throw error;
    }
}
