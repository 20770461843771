import {Link} from "react-router-dom";
import React from "react";
import {ReactComponent as UserIcon} from "./../../../assets/UserIcon.svg"

const RepairCustomer = ({repair}) => {
    return <Link to={`/customer/${repair && repair.customer.id}/edit`}
                 className={"bg-white p-5 rounded-md flex flex-col items-center gap-5 "}>
        <UserIcon/>
        <div className={"text-center"}>
            <span className={"block text-lg font-bold"}>{repair && repair.customer.name}</span>
            <span className={"block text-gray-500 text-sm"}>
                {repair && repair.customer.phoneNumber1}
                {repair && repair.customer.phoneNumber2 && repair.customer.phoneNumber2 !== "null" &&
                    ` - ${repair.customer.phoneNumber2}`}
            </span>
        </div>
    </Link>
}

export default RepairCustomer
