import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";

export async function technicianCreate(requestBody) {
    try {
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/technician/`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
}

export async function technicianUpdate(idCustomer, requestBody) {

    try {
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/technician/${idCustomer}/`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
}

export const techniciansGetList = async (page = 0, size = 999999, sort = "id,desc", searchQuery = null) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/technician/?page=${page}&size=${size}&sort=${sort}` +
                `${searchQuery != null ? "&searchQuery="+searchQuery : ""}`
        });
        return response
    } catch (error) {
        throw error;
    }
};


export const technicianGetDetails = async (id) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/technician/${id}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const technicianDelete = async (id) => {
    try {
        const response = await callApiWithAuthentication({
            method: "delete",
            url: `${window.API_URL}/technician/${id}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};
