import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {partnerGetDetails, partnerUpdate} from "../../services/PartnerService";
import {useTranslation} from "react-i18next";

export default function PartnerEdit(props) {

    const {idPartner} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);
    const [floor, setFloor] = useState(null);
    const [hours, setHours] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPartner = async () => {
            try {
                const partnerData = await partnerGetDetails(idPartner);
                setName(partnerData.alias);
                setAddress(partnerData.address);
                setFloor(partnerData.floor);
                setHours(partnerData.hours);
            } catch (error) {
                alert('Failed to fetch partner data:', error);
            }
        };

        fetchPartner();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await partnerUpdate(idPartner, {
                "alias": name, "address": address, "floor": floor, "hours": hours,
            });
        } catch (error) {
            alert("error");
            setLoading(false)
            return
        }

        setLoading(false)
        navigate("/partner/list")
    }

    return <Panel header={t("Edit Partner")}>
        <form onSubmit={formSubmit}>
            <div className={"flex gap-4"}>
                <FormControl label={t("Name")} required={true}>
                    <InputText placeholder={t("Name")} name={"name"} defaultValue={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                               }}/>
                </FormControl>
                <FormControl label={t("Address")} required={true}>
                    <InputText placeholder={t("Address")} name={"address"} defaultValue={address}
                               onChange={(e) => {
                                   setAddress(e.target.value)
                               }}/>
                </FormControl>
            </div>
            <div className={"flex gap-4"}>
                <FormControl label={t("Floor")}>
                    <InputText placeholder={t("Floor")} name={"floor"} defaultValue={floor}
                               onChange={(e) => {
                                   setFloor(e.target.value)
                               }}/>
                </FormControl>
                <FormControl label={t("Ηours of operation")}>
                    <InputText placeholder={t("Ηours of operation")} name={"hours"} defaultValue={hours}
                               onChange={(e) => {
                                   setHours(e.target.value)
                               }}/>
                </FormControl>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Create Partner")} loading={loading}/>
            </div>
        </form>
    </Panel>;
}
