import React, {useEffect, useState} from "react";
import {repairAddComment, repairUpdate} from "../../../services/RepairService";
import {useTranslation} from "react-i18next";
import {Checkbox} from "primereact/checkbox";
import FormControl from "../../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import NavbarLink from "../../../components/NavbarLink/NavbarLink";
import {urlContains} from "../../../utils/urlContains";
import {Dropdown} from "primereact/dropdown";
import {userGetDetails} from "../../../services/UserService";
import {techniciansGetList} from "../../../services/TechnicianService";
import {setSelectionRange} from "@testing-library/user-event/dist/utils";


const RepairTechnicianTabForm = ({repair, toast, setLoading, reloadRepair, user}) => {
    const {t, i18n} = useTranslation();

    const [comment, setComment] = useState(null);
    const [technicians, setTechnicians] = useState([]);
    const [selectedTechnician, setSelectedTechnician] = useState(null);

    const fetchTechnicians = async () => {
        const techniciansData = await techniciansGetList();
        setTechnicians(techniciansData.content)
    }
    useEffect(() => {
        fetchTechnicians()
    }, [])
    useEffect(() => {
        if (repair && repair.technician) {
            setSelectedTechnician(repair.technician)
        }
    }, [repair])

    const formSubmit = async (e) => {
        e.preventDefault()

        setLoading(true);
        try {
            await repairAddComment(repair.id, comment);
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατά την δημιουργία του πελάτη',
                life: 3000,
                position: "bottom"
            });
            return
        }

        reloadRepair()
        setLoading(false);
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair updated successfully'),
            life: 3000
        });
    }

    const updateRepairTechnician = async (idTechnician) => {
        setLoading(true);
        try {
            await repairUpdate(repair.id, null, null, null, null, null, null, null,
                [], null, null, null, idTechnician)
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            return
        }
        reloadRepair()
        setLoading(false);
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair updated successfully'),
            life: 3000
        });
    }

    return <form>
        <div className={"flex gap-5"}>

            {user && user.store.configuration.hasTechnicians && <div className={"w-full"}>
                <FormControl label={t("Technician")} required={true}>
                    <Dropdown options={technicians} optionLabel="name" value={selectedTechnician}
                              onChange={(s) => {
                                  updateRepairTechnician(s.value.id)
                              }}/>
                </FormControl>
            </div>
            }
            <form className={"w-full"}>
                <FormControl label={t("Technician Comments")} required={true}>
                    <div className="card flex justify-content-center">
                        <InputTextarea defaultValue={comment} className={"w-full"}
                                       placeholder={t("Technician Comments")}
                                       onChange={(e) => setComment(e.target.value)}
                                       rows={10} cols={50}/>
                    </div>
                </FormControl>
                <div className={"text-right "}>
                    <Button type="submit" icon="pi pi-save" className={"text-lg π-"} label={t("Add Comment")}
                            onClick={formSubmit}/>
                </div>
            </form>
        </div>
    </form>
}

export default RepairTechnicianTabForm
