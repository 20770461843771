import {MdAddToPhotos} from "react-icons/md";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    statisticsGetNewRepairs,
    statisticsGetProfit,
    statisticsGetRepairsInPartner,
    statisticsGetRevenue
} from "../../../../services/StatisticsService";
import StatisticsPeriodOverPeriodKPI from "./StatisticsPeriodOverPeriodKPI";
import {FaCashRegister, FaHandshake, FaMoneyBill} from "react-icons/fa";
import StatisticsKPI from "./StatisticsKPI";

export default function Statistics() {
    const {t, i18n} = useTranslation();

    const [newRepairsStats, setNewRepairsStats] = useState(null);
    const [revenueStats, setRevenueStats] = useState(null);
    const [profitStats, setProfitStats] = useState(null);
    const [repairsInPartner, setRepairsInPartner] = useState(null);

    const loadStatistics = async () => {
        setNewRepairsStats(await statisticsGetNewRepairs(30));
        setRevenueStats(await statisticsGetRevenue(30));
        setProfitStats(await statisticsGetProfit(30));
        setRepairsInPartner(await statisticsGetRepairsInPartner());
    }

    useEffect(() => {
        loadStatistics();
    }, []);

    return <div className={"flex gap-5 mb-5"}>
        <StatisticsPeriodOverPeriodKPI stats={newRepairsStats} label={t("New Repairs / per month")} unit={t("Repairs")} Icon={MdAddToPhotos}/>
        <StatisticsPeriodOverPeriodKPI stats={revenueStats} label={t("Turnover / per month")} type={"money"} Icon={FaCashRegister}/>
        <StatisticsPeriodOverPeriodKPI stats={profitStats} label={t("Profit / per month")} type={"money"} Icon={FaMoneyBill }/>
        <StatisticsKPI number={repairsInPartner} label={t("Repairs in Partners")} Icon={FaHandshake}/>
    </div>
}
