import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as CurrentLocationIcon} from "./../../../assets/CurrentLocationIcon.svg";
import FormControl from "../../../components/UI/FormControl";
import RepairTransferToStore from "./RepairTransferToStore";
import RepairTransferToPartner from "./RepairTransferToPartner";
import formatDate from "../../../utils/formatDate";


const RepairLocationTabForm = ({repair, stores, partners, toast, reloadRepair}) => {
    const {t, i18n} = useTranslation();


    const getLocationTypeText = (locationType) => {
        switch (locationType) {
            case "STORE":
                return t("Store")
            case "EXTERNAL_PARTNER":
                return t("External Partner")
        }
    }

    return <>
        <div className="flex gap-10 items-start">
            <div className="w-full">
                <FormControl label={t("Current Location")}>
                    <div className={"current-location w-full rounded-md"}>
                        <div className={"p-4 flex items-center gap-4"}>
                            <CurrentLocationIcon style={{width: "40px"}}/>
                            <div>
                                <span className={"block text-gray-500 text-sm"}>
                                    {repair && getLocationTypeText(repair.history[repair.history.length - 1].locationType)}
                                </span>
                                <span className={"block font-bold text-lg"}>
                                    {repair && repair.history[repair.history.length - 1].location.alias}
                                </span>
                            </div>
                        </div>
                    </div>
                </FormControl>

                <FormControl label={t("Transfer Repair")}>
                    <div className={"flex w-full gap-5"}>
                        <RepairTransferToStore repair={repair} toast={toast} stores={stores}
                                               reloadRepair={reloadRepair}/>
                        <RepairTransferToPartner repair={repair} toast={toast} partners={partners} stores={stores}
                                                 reloadRepair={reloadRepair}/>
                    </div>
                </FormControl>
            </div>
            <div className="w-full">
            </div>

        </div>
    </>
}

export default RepairLocationTabForm
