import {Panel} from "primereact/panel";
import {TabPanel, TabView} from "primereact/tabview";
import FormControl from "../../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {customersGetList} from "../../../services/CustomerService";
import {InputNumber} from "primereact/inputnumber";

export default function RepairCreateCustomer(props) {
    const {t, i18n} = useTranslation();
    const [customers, setCustomers] = useState([]);
    const [customerSearchLoading, setCustomerSearchLoading] = useState(false);

    const fetchCustomers = async (searchQuery=null) => {
        setCustomerSearchLoading(true)
        try {
            const customersData = await customersGetList(0,50, "id,desc", searchQuery);
            customersData.content.map((customer) => {
                customer.template = customerOptionTemplate(customer)
            })
            setCustomers(customersData.content);
            setCustomerSearchLoading(false)
        } catch (error) {
            setCustomerSearchLoading(false)
            props.toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατα την φόρτωση των πελατών',
                life: 3000,
                position: "bottom"
            });
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const selectedCustomerTemplate = (customer, props) => {
        if (customer) {
            return customerOptionTemplate(customer)
        }

        return <span>{props.placeholder}</span>;
    };

    const onCustomerSearchFilter = (e) => {
        // console.log(e);
        fetchCustomers(e.filter);
    }

    const customerOptionTemplate = (customer) => {
        return `${customer.name} ${customer.companyName ? ` από ${customer.companyName}` : ""}` +
            `${customer.vatNumber ? ` (Α.Φ.Μ. ${customer.vatNumber})` : ""}`
    };

    const getCountyCodePrefix = () => {
        return (props.storeConfiguration && props.storeConfiguration.id === 71) ? "357" : "30"
    };

    return <Panel header={t("Customer")} className={"w-full"}>
        <TabView>
            <TabPanel header={t("New Customer")}>
                <FormControl label={t("Customer Name")} required={true}>
                    <div className="card flex justify-content-center">
                        <InputText placeholder={t("Customer Name")} className={"w-full"}
                                   onChange={(e) =>
                                       props.setCustomerName(e.target.value.replace(",", "."))}
                        />
                    </div>
                </FormControl>
                <div className={"flex gap-5"}>
                    <FormControl label={t("Phone Number Mobile")} required={true}>
                        <div className="card flex justify-content-center" >
                            <div className="p-inputgroup flex-1 w-full">
                                <span className="p-inputgroup-addon">+{getCountyCodePrefix()}</span>
                                <InputText placeholder={t("Phone Number Mobile")} keyfilter="int"
                                           onChange={(e) =>
                                               props.setCustomerTelephone(e.target.value.replace(",", "."))}
                                />
                            </div>
                        </div>
                    </FormControl>
                    <FormControl label={t("Phone Number Landline")}>
                        <div className="card flex justify-content-center">
                            <div className="p-inputgroup flex-1 w-full">
                                <span className="p-inputgroup-addon">+{getCountyCodePrefix()}</span>
                                <InputText placeholder={t("Phone Number Landline")} keyfilter="int"
                                           className={"w-full"}
                                           onChange={(e) =>
                                               props.setCustomerTelephone2(e.target.value.replace(",", "."))}
                                />
                            </div>
                        </div>
                    </FormControl>
                </div>
            </TabPanel>
            <TabPanel header={t("Recurring Customer")}>
                <FormControl label={t("Customer")} required={true}>
                    <div className="card flex justify-content-center">
                        <Dropdown value={props.selectedCustomer}
                                  onChange={(e) => props.setSelectedCustomer(e.value)}
                                  options={customers} optionLabel="name" loading={customerSearchLoading}
                                  placeholder={t("Chose Customer")} onFilter={onCustomerSearchFilter}
                                  filter filterBy={"template"} valueTemplate={selectedCustomerTemplate}
                                  itemTemplate={customerOptionTemplate} className="w-full md:w-14rem"/>
                    </div>
                </FormControl>
            </TabPanel>
        </TabView>
    </Panel>
}
