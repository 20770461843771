import React, {useState} from "react";
import {FaLocationArrow} from "react-icons/fa";
import {useTranslation} from "react-i18next";

import {ReactComponent as ServiceIcon} from "./../../../assets/RepairFilled.svg";
import {IoPricetag} from "react-icons/io5";
import RepairTab from "./RepairTab";
import RepairTabContent from "./RepairTabContent";
import RepairTabForm from "./RepairTabForm";
import RepairBillingTabForm from "./RepairBillingTabForm";
import RepairLocationTabForm from "./RepairLocationTabForm";
import {GrUserSettings} from "react-icons/gr";
import RepairTechnicianTabForm from "./RepairTechnicianTabForm";


const RepairTabs = ({repair, toast, setLoading, reloadRepair, stores, partners, user}) => {

    const {t, i18n} = useTranslation();
    const [selectedTab, setSelectedTab] = useState(1);

    return <>
        <div className={"flex w-full items-center gap-5 mb-5"}>
            <RepairTab text={t("Repair")} icon={<ServiceIcon/>} isSelected={selectedTab === 1} callback={() => {
                setSelectedTab(1)
            }}/>
            <RepairTab text={t("Location")} icon={<FaLocationArrow/>} isSelected={selectedTab === 2} callback={() => {
                setSelectedTab(2)
            }}/>
            <RepairTab text={t("Billing")} icon={<IoPricetag/>} isSelected={selectedTab === 3} callback={() => {
                setSelectedTab(3)
            }}/>
            <RepairTab text={t("Technician")} icon={<GrUserSettings />} isSelected={selectedTab === 4} callback={() => {
                setSelectedTab(4)
            }}/>
        </div>

        <RepairTabContent isSelected={selectedTab === 1} text={t("Repair")}>
            <RepairTabForm repair={repair} toast={toast} setLoading={setLoading} reloadRepair={reloadRepair} user={user}/>
        </RepairTabContent>
        <RepairTabContent isSelected={selectedTab === 2} text={t("Location")}>
            <RepairLocationTabForm repair={repair} toast={toast} setLoading={setLoading} reloadRepair={reloadRepair}
                                   stores={stores} partners={partners}/>
        </RepairTabContent>
        <RepairTabContent isSelected={selectedTab === 3} text={t("Billing")}>
            <RepairBillingTabForm repair={repair} toast={toast} setLoading={setLoading} reloadRepair={reloadRepair}/>
        </RepairTabContent>
        <RepairTabContent isSelected={selectedTab === 4} text={t("Technician")}>
            <RepairTechnicianTabForm repair={repair} toast={toast} setLoading={setLoading} reloadRepair={reloadRepair} user={user}/>
        </RepairTabContent>
    </>
}

export default RepairTabs
