import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PlusIcon} from "primereact/icons/plus";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {technicianDelete, techniciansGetList} from "../../services/TechnicianService";

export default function TechnicianList(props) {

    const toast = useRef(null);
    const [technicians, setTechnicians] = useState([]);
    const [selectedTechnicians, setSelectedTechnicians] = useState([]);

    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("id,desc");
    const [size, setSize] = useState(50);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const fetchTechnicians = async () => {
        setLoading(true)
        try {
            const technicianPage = await techniciansGetList(page, size, sort);
            setTechnicians(technicianPage.content);
            setTotalRecords(technicianPage.totalElements);
            setLoading(false);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTechnicians();
    }, [page, sort, size]);

    const deleteTechnician = async (technician) => {
        setLoading(true)
        try {
            await technicianDelete(technician.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Technician deleted successfully'),
            life: 3000,
            position: "bottom"
        });
        fetchTechnicians()
    }

    const closeModal = () => {
    }

    const confirmDelete = (technician) => {
        confirmDialog({
            message: t("Are you sure you'd like to delete this technician"),
            header: t("Delete Technician"),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                deleteTechnician(technician)
            },
            reject: () => {
                closeModal()
            }
        });
    }

    const getHeader = () => {
        return <div className={"flex items-center justify-between w-full"}>
            <span>{t("Technicians")}</span>
            <Button icon={<PlusIcon color={"white"} className={"mr-2"}/>} onClick={() => {
                navigate("/technician/create")
            }} label={t("New Technician")}/>
        </div>
    }

    const getActionsButtons = (technician) => {
        return <>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={"Επεξεργασία πελάτη"}
                    tooltipOptions={{position: 'bottom'}} icon={<AiFillEdit color={"grey"} onClick={() => {
                navigate(`/technician/${technician.id}/edit`)
            }
            }/>}/>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        confirmDelete(technician)
                    }}
                    tooltip={"Διαγραφή πελάτη"} icon={<TrashIcon color={"grey"}/>}/>
        </>
    }
    const onPage = (e) => {
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const onSort = (e) => {
        setSort(`${e.sortField},${e.sortOrder === 1 ? "desc" : "asc"}`)
    }



    return <>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>
        <Panel header={getHeader()}>
            <DataTable
                value={technicians} showGridlines  selection={selectedTechnicians}
                onSelectionChange={(e) => setSelectedTechnicians(e.value)}
                stripedRows className={"w-full"} size={"small"} paginator
                totalRecords={totalRecords} lazy loading={loading} onSort={onSort}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={t("Total") + " : {totalRecords} " + t("Technicians")}
                first={first} onPage={onPage} rows={size} pageCount={5} rowsPerPageOptions={[5, 10, 25, 50]}
                emptyMessage={t("No technicians found")}
                dataKey="id"
            >
                <Column field="name"  header={t("Name")}/>
                <Column field="phoneNumber"  header={t("Telephone")}></Column>
                <Column field="actions" header="Ενέργειες" body={getActionsButtons}/>
            </DataTable>
        </Panel>
    </>;
}
