import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {locale} from "primereact/api";
import {Dropdown} from "primereact/dropdown";
import "./LanguageSelector.css";

export default function LanguageSelector() {
    const [language, setLanguage] = useState(window.LANGUAGE_OPTIONS[0]);
    const {t, i18n} = useTranslation();

    const setLanguageToLocalStorage = (option) => {
        localStorage.setItem("language", JSON.stringify(option))
        locale(option.code)
        setLanguage(option)
        i18n.changeLanguage(option.code).then(() => {
            window.location.reload()
        });
    }

    const setLanguageFromLocalStorage = ()=>{
        let language = JSON.parse(localStorage.getItem("language"))
        if (language == null) {
            language = window.LANGUAGE_OPTIONS[0]
        }
        i18n.changeLanguage(language.code).then(r => {
            setLanguage(language)
            locale(language.code)
        })
    }

    useEffect(() => {
        setLanguageFromLocalStorage();
    }, []);

    const languageTemplate = (lang)=> {
        return <div className={"flex gap-2 items-center"}>
            <img width="32px" height="32px" src={lang.icon}/>
            <span>{lang.name}</span>
        </div>
    }

    return <Dropdown className={`language-selector border-0 p-0 w-full`} value={language} onChange={(e) => setLanguageToLocalStorage(e.value)}
                     itemTemplate={languageTemplate}
                     valueTemplate={languageTemplate(language)}
                     options={window.LANGUAGE_OPTIONS} optionLabel="name"
                     placeholder="Επιλογή γλώσσας"
    />
}
