import React from "react";


const RepairTab = ({text, icon, isSelected, callback}) => {
    return <>
        <button className={`repair-tab flex items-center p-3 gap-5 rounded-md w-full justify-center` +
            ` ${isSelected ? "selected" : "bg-white"}`}
                onClick={() => {
                    callback()
                }}
        >
            {icon}
            <span className={"text-lg"}>{text}</span>
        </button>
    </>
}

export default RepairTab
