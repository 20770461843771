import React, {useEffect, useRef, useState} from "react";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import {repairDelete, repairsGetList, repairsSearch} from "../../services/RepairService";
import {userGetDetails} from "../../services/UserService";
import "./RepairList.css";
import RepairListLayout from "./parts/RepairListLayout";
import Statistics from "./parts/Statistics/Statistics";
import {FilterMatchMode} from "primereact/api";

export default function RepairList(props) {

    const toast = useRef(null);

    const [repairs, setRepairs] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [size, setSize] = useState(50);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);
    const [storeConfiguration, setStoreConfiguration] = useState(false);
    const [reloadRepairs, setReloadRepairs] = useState(false);
    const {t, i18n} = useTranslation();
    const [layout, setLayout] = useState("default");
    const [replaceOrAddToRepairs, setReplaceOrAddToRepairs] = useState("replace");
    const [filters, setFilters] = useState({
        delivered: {value: null, matchMode: FilterMatchMode.EQUALS},
        serviced: {value: null, matchMode: FilterMatchMode.EQUALS},
    });

    const getLayoutFromLocalStorage = () => {
        const repairListLayout = localStorage.getItem("repairListLayout")
        if (repairListLayout === "" || repairListLayout == null) {
            return "default";
        }
        return repairListLayout;
    }
    const setLayoutToLocalStorage = (value) => {
        localStorage.setItem("repairListLayout", value)
        setLayout(value)
    }

    const fetchRepairs = async () => {
        setLoading(true);
        try {
            let repairsResult = [];
            if (searchQuery != null && searchQuery.length > 3) {
                repairsResult = await repairsSearch(searchQuery);
                setRepairs(repairsResult);
                setTotalRecords(repairsResult.length);
            } else {
                repairsResult = await repairsGetList(
                    page,
                    getSortOrderForCall(),
                    filters.serviced.value,
                    filters.delivered.value
                );
                if (replaceOrAddToRepairs === "replace") {
                    setRepairs(repairsResult.serviceItems)
                    setTotalRecords(repairsResult.pagesTotalNumber * repairsResult.serviceItems.length);
                } else {
                    const tmpRepairs = repairs
                    repairsResult.serviceItems.map((r) => tmpRepairs.push(r))
                    setRepairs(tmpRepairs)
                }
            }
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while loading repairs'),
                life: 3000,
                position: "bottom"
            });
        } finally {
            setLoading(false);  // Ensure loading is set to false, regardless of success or error
        }
    };

    const fetchStoreConfiguration = async () => {
        try {
            const userDetails = await userGetDetails()
            setStoreConfiguration(userDetails.store.configuration)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch user data:', error);
        }
    }

    useEffect(() => {
        fetchRepairs();
    }, [page, sortOrder, sortField, size, searchQuery, reloadRepairs, filters]);

    useEffect(() => {
        setLayout(getLayoutFromLocalStorage())
        fetchStoreConfiguration()
    }, []);

    const getSortOrderForCall = () => {
        if (sortOrder == null) {
            return "NEW"
        }
        return sortOrder === -1 ? "NEW" : "OLD";
    }

    const deleteRepair = async (repair) => {
        setLoading(true)
        try {
            await repairDelete(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while deleting repair'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair deleted successfully'),
            life: 3000
        });
        setReloadRepairs(!reloadRepairs)
    }

    const onPage = (e) => {
        setReplaceOrAddToRepairs("replace");
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const onSort = (e) => {
        setReplaceOrAddToRepairs("replace");
        setSortOrder(e.sortOrder)
        setSortField(e.sortField)
    }

    const loadNextPage = (e) => {
        setReplaceOrAddToRepairs("add");
        setPage(page + 1)
    }


    return <>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>

        <Statistics/>

        {/*<div className={"absolute top-5 right-10"}>*/}
        {/*    <LayoutSelector layout={layout} setLayout={setLayoutToLocalStorage}  loading={loading}/>*/}
        {/*</div>*/}

        {/*{layout === "grid" ?*/}
        {/*    <RepairGridLayout repairs={repairs} loadNextPage={loadNextPage} loading={loading} />*/}
        {/*    :*/}
        <RepairListLayout repairs={repairs} deleteRepair={deleteRepair} searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery} setLoading={setLoading} loading={loading}
                          sortOrder={sortOrder} size={size} totalRecords={totalRecords} sortField={sortField}
                          storeConfiguration={storeConfiguration} first={first} onPage={onPage} onSort={onSort}
                          filters={filters} setFilters={setFilters}
        />
        {/*}*/}
    </>;
}
