import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import React, {useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Link, useNavigate} from "react-router-dom";
import {PlusIcon} from "primereact/icons/plus";
import {Panel} from "primereact/panel";
import {confirmDialog} from "primereact/confirmdialog";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {useTranslation} from "react-i18next";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {GrPrint} from "react-icons/gr";

export default function RepairListLayout(props) {
    const [selectedRepairs, setSelectedRepairs] = useState([]);
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const searchQueryField = useRef(null);


    const showBulkOptions = (e) => {
        return <Button
            outlined disabled={selectedRepairs.length === 0} label="Διαγραφή" icon="pi pi-times"
            onClick={() => {
                confirmDialog({
                    message: 'Έιστε σίγουρος πως θέλετε να διαγράψετε τους επιλεγμένες επισκευές;',
                    header: 'Διαγραφή επιλεγμένων επισκευών',
                    icon: 'pi pi-info-circle',
                    defaultFocus: 'reject',
                    acceptClassName: 'p-button-primary',
                    acceptLabel: t("Yes"),
                    rejectLabel: t("No"),
                    accept: () => {
                        selectedRepairs.map((repair) => {
                            props.deleteRepair(repair)
                        })
                    },
                    reject: () => {
                        closeModal()
                    }
                });
            }}/>
    }

    const confirmDelete = (repair) => {
        confirmDialog({
            message: 'Έιστε σίγουρος πως θέλετε να διαγράψετε αυτήν την επισκευή;',
            header: 'Διαγραφή επισκευής',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                props.deleteRepair(repair)
            },
            reject: () => {
                closeModal()
            }
        });
    }

    const closeModal = () => {
    }

    const getActionsButtons = (repair) => {
        return <>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={t("Edit Repair")}
                    tooltipOptions={{position: 'bottom'}} icon={<AiFillEdit color={"grey"} onClick={() => {
                props.setLoading(true)
                navigate(`/repair/${repair.id}/edit`)
            }
            }/>}/>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        confirmDelete(repair)
                    }}
                    tooltip={t("Delete Repair")} icon={<TrashIcon color={"grey"}/>}/>

            {props.storeConfiguration && props.storeConfiguration.adminPanelPrintingType != "none" &&
                <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                        tooltip={t("Reprint Repair Delivery Receipt")} icon={<GrPrint color={"grey"}/>} onClick={() => {
                    redirectToDeliveryReceipt(repair)
                }}/>}
        </>
    }

    const redirectToDeliveryReceipt = (repair) => {
        if (props.storeConfiguration.adminPanelPrintingType === "a4") {
            window.open(`/repair/${repair.id}/delivery-receipt`, '_blank').focus();
        } else if (props.storeConfiguration.adminPanelPrintingType === "a5") {
            window.open(`/repair/${repair.id}/delivery-receipt-a5`, '_blank').focus();
        }
    }


    const getHeader = () => {
        return <div className={"flex items-center justify-between w-full"}>
            <span>{t("Repairs")}</span>
            <div className={"flex gap-4"}>
                <span className="p-input-icon-right">
                    {props.searchQuery == null ? <i className="pi pi-search"/> :
                        <i className="pi pi-times cursor-pointer" onClick={() => {
                            props.setSearchQuery(null)
                            searchQueryField.current.value = null;
                        }}/>}
                    <InputText placeholder={t("Search...")} ref={searchQueryField} onChange={(e) => {
                        props.setSearchQuery(e.target.value)
                    }}/>
                </span>
                <Link to={"/repair/create"}>
                    <Button icon={<PlusIcon color={"white"} className={"mr-2"}/>} label={t("New Repair")}/>
                </Link>
            </div>
        </div>
    }

    const getDeliveredColumnBody = (repair) => {
        return repair.delivered ?
            <Tag className="mr-2" icon="pi pi-check" severity="success" value={t("Delivered")}/> : ""
    }

    const getServicedColumnBody = (repair) => {
        if (repair.serviced === true) {
            return <Tag className="mr-2" icon="pi pi-check" severity="success"
                        value={t("Serviced")}/>
        }

        if (repair.serviced === false) {
            return <Tag className="mr-2" icon="pi pi-times" severity="danger"
                        value={t("Unserviced")}/>
        }
    }

    const tristateFilterTemplate = (label, options) => {
        return <div className="flex align-items-center justify-between">
                <label htmlFor="verified-filter" className="font-bold">
                    {label}
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value}
                                  onChange={(e) => options.filterCallback(e.value)}/>
            </div>
    }
    const deliveredFilterTemplate = (options) => {
        return tristateFilterTemplate(t("Delivered"), options)
    };

    const sendSMSFilterTemplate = (options) => {
        return tristateFilterTemplate(t("Sent SMS"), options)
    };
    const servicedFilterTemplate = (options) => {
        return tristateFilterTemplate(t("Serviced"), options)
    };

    const onRowClick = (row) => {
        const repair = row.data
        navigate(`/repair/${repair.id}/edit`)
    }
    const onFilter = (params) => {
        props.setFilters(params.filters)
    }

    return <Panel header={getHeader()}>
        <DataTable
            value={props.repairs} showGridlines selectionMode={null} selection={selectedRepairs}
            onSelectionChange={(e) => setSelectedRepairs(e.value)}
            onRowClick={onRowClick}
            stripedRows className={"w-full"} size={"small"} paginator
            totalRecords={props.totalRecords} lazy loading={props.loading}
            onSort={props.onSort} sortField={props.sortField} sortOrder={props.sortOrder}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={t("Total: {totalRecords} Repairs")}
            first={props.first} onPage={props.onPage} rows={props.size}
            emptyMessage={"Δεν βρέθηκαν επισκευές"} filters={props.filters} onFilter={onFilter}
            dataKey="id" paginatorLeft={showBulkOptions()}
        >
            <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
            <Column field="dateAdd" header={t("Date")} sortable body={(repair) => {
                const date = new Date(repair.dateAdd * 1000);
                const day = String(date.getDate()).padStart(2, '0'); // Get the day, ensure two digits
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed), ensure two digits
                const year = date.getFullYear(); // Get the full year
                return `${day}/${month}/${year}`;
            }}/>
            <Column field="code" header={t("Code")} body={(repair) => {
                return <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={repair.description}
                               tooltipOptions={{position: 'bottom'}}>
                    <Tag severity="success" value={`#${repair.code}`}> </Tag>
                </Button>
            }}/>
            <Column field="customer.name" header={t("Customer Name")}/>
            <Column field="customer.phoneNumber1" header={t("Customer Telephone")}/>
            {props.storeConfiguration && props.storeConfiguration.hasTechnicians &&
                <Column field="technician.name" header={t("Technician")}/>
            }
            {props.storeConfiguration && props.storeConfiguration.hasSerialNumber &&
                <Column field="serialNumber" header={t("Serial Number")} body={(repair) => {
                    return repair.serialNumber && !isNaN(repair.serialNumber) ? repair.serialNumber : ""
                }}></Column>
            }
            {props.storeConfiguration && props.storeConfiguration.hasSecurityCode &&
                <Column field="serialNumber" header={t("Security Code")} body={(repair) => {
                    return repair.securityCode && !isNaN(repair.securityCode) ? repair.securityCode : ""
                }}></Column>
            }
            <Column field="price" header={t("Repair Amount")} body={(repair) => {
                if (repair.price == null) {
                    return ""
                }
                return <b>{repair.price.toFixed(2) + "€"}</b>
            }}></Column>
            <Column field="serviced" header={t("Serviced")} dataType="boolean" bodyClassName="text-center"
                    style={{minWidth: '8rem'}} body={getServicedColumnBody} filter
                    filterElement={servicedFilterTemplate}
            />
            <Column field="sentSms" header={t("Sent SMS")} dataType="boolean" bodyClassName="text-center"
                    style={{minWidth: '8rem'}}
                    body={(order) => {
                        return order.sentSms ?
                            <Tag className="mr-2" icon="pi pi-check" severity="success"
                                 value={t("Sent SMS")}/> : ""
                    }}
            />
            <Column field="delivered" header={t("Delivered")} dataType="boolean" bodyClassName="text-center"
                    style={{minWidth: '8rem'}} body={getDeliveredColumnBody} filter filterElement={deliveredFilterTemplate}
            />
            <Column field="actions" header={t("Actions")} body={getActionsButtons}/>
        </DataTable>
    </Panel>
}
