import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PlusIcon} from "primereact/icons/plus";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import {partnerDelete, partnersGetList} from "../../services/PartnerService";
import {useNavigate} from "react-router-dom";

export default function PartnerList(props) {

    const toast = useRef(null);
    const [partners, setPartners] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);

    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("id,desc");
    const [size, setSize] = useState(20);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const fetchPartners = async () => {
        setLoading(true)
        try {
            const response = await partnersGetList(page);
            setPartners(response.partnerList);
            setTotalRecords((response.pagesTotalNumber - 1) * 20 + response.partnerList.length);
            setLoading(false);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατα την φόρτωση των πελατών',
                life: 3000,
                position: "bottom"
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartners();
    }, [page, sort, size]);

    const deletePartner = async (partner) => {
        setLoading(true)
        try {
            await partnerDelete(partner.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Successfull Delete'),
            life: 3000
        });
        fetchPartners()
    }

    const closeModal = () => {
    }

    const confirmDelete = (partner) => {
        confirmDialog({
            message: t("Are you sure you'd like to delete this partner?"),
            header: t("Delete Partner"),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                deletePartner(partner)
            },
            reject: () => {
                closeModal()
            }
        });
    }

    const getHeader = () => {
        return <div className={"flex items-center justify-between w-full"}>
            <span>{t("External Partners")}</span>
            <Button icon={<PlusIcon color={"white"} className={"mr-2"}/>} onClick={() => {
                navigate("/partner/create");
            }} label={t("New Partner")}/>
        </div>
    }

    const getActionsButtons = (partner) => {
        return <>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={t("Edit Partner")}
                    tooltipOptions={{position: 'bottom'}} icon={<AiFillEdit color={"grey"} onClick={() => {
                setLoading(true)
                navigate(`/partner/${partner.id}/edit`)
            }
            }/>}/>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        confirmDelete(partner)
                    }}
                    tooltip={t("Delete Partner")} icon={<TrashIcon color={"grey"}/>}/>
        </>
    }

    const onPage = (e) => {
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const onSort = (e) => {
        setSort(`${e.sortField},${e.sortOrder === 1 ? "desc" : "asc"}`)
    }


    const showBulkOptions = (e) => {
        return <Button
            outlined disabled={selectedPartners.length === 0} label="Διαγραφή" icon="pi pi-times"
            onClick={() => {
                confirmDialog({
                    message: t("Are you sure you'd like to delete the selected partners?"),
                    header: t("Delete Selected Partners"),
                    icon: 'pi pi-info-circle',
                    defaultFocus: 'reject',
                    acceptClassName: 'p-button-primary',
                    acceptLabel: t("Yes"),
                    rejectLabel: t("No"),
                    accept: () => {
                        selectedPartners.map((partner) => {
                            deletePartner(partner)
                        })
                    },
                    reject: () => {
                        closeModal()
                    }
                });
            }}/>
    }

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>
        <Panel header={getHeader()}>
            <DataTable
                value={partners} showGridlines selectionMode={'checkbox'} selection={selectedPartners}
                onSelectionChange={(e) => setSelectedPartners(e.value)}
                stripedRows className={"w-full"} size={"small"} paginator
                totalRecords={totalRecords} lazy loading={loading}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={t("Total") + " : {totalRecords} " + t("Partners")}
                first={first} onPage={onPage} rows={size} pageCount={5} rowsPerPageOptions={[20]}
                emptyMessage={t("No partners found")}
                dataKey="id" paginatorLeft={showBulkOptions()}
            >
                <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                <Column field="alias" header={t("Partner Name")}/>
                <Column field="address" header={t("Address")}></Column>
                <Column field="hours" header={t("Ηours of operation")}></Column>
                <Column field="floor" header={t("Όροφος")}></Column>
                <Column field="actions" header="Ενέργειες" body={getActionsButtons}/>
            </DataTable>
        </Panel>
    </>;
}
