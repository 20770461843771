import React from "react";


const RepairTabContent = (props) => {
    return <div className={`main-tab bg-white w-full rounded-md ${!props.isSelected && "hidden"}`}>
        <div className={"p-5"}>
            <span className={"text-xl font-bold mb-2 block"}>{props.text}</span>
            {props.children}
        </div>
    </div>
}

export default RepairTabContent
