import React from "react";
import {useTranslation} from "react-i18next";
import formatPrice from "../../../../utils/formatPrice";

export default function StatisticsKPI({number, label, unit, Icon, type}) {
    const {t, i18n} = useTranslation();

    const formatValue = (value) => {
        return type && type === "money" ? formatPrice(value) : value;
    }

    return <div className={"bg-white rounded-md p-5 w-full gap-5 flex items-center"}>
        <div className={"bg-gray flex p-4 items-center justify-center rounded-full"}
             style={{backgroundColor: "#EAEAEA"}}>
            <Icon color={"#7C7C7C"} size={25}/>
        </div>
        <div className={""}>
            <span className={"block text-gray-500 text-sm"}>{label}</span>
            {number && <div className={"flex items-center gap-2"}>
                <span className={"font-bold color-black text-xl"}>{formatValue(number)}</span>
            </div>}
        </div>
    </div>
}
