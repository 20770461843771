import React, {useEffect, useState} from "react";
import {repairTransferToPartner} from "../../../services/RepairService";
import {useTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import FormControl from "../../../components/UI/FormControl";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {ReactComponent as PartnerIcon} from "./../../../assets/Partner.svg";


const RepairTransferToPartner = ({repair, partners, toast, reloadRepair, stores}) => {

    const {t, i18n} = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(false);
    const [transferComments, setTransferComments] = useState("");
    const [isStoreInPartner, setIsStoreInPartner] = useState(false);

    useEffect(() => {
        if (!partners) return;
        let alreadyChosenSelected = false;
        partners.map((partner) => {
            const disabled = partner.id === repair.history[repair.history.length - 1].location.id
            if (!disabled && !alreadyChosenSelected) {
                setSelectedPartner({
                    "partnerDesc": `${partner.alias} (${partner.address})`,
                    "id": partner.id,
                    "disabled": disabled,
                });
                alreadyChosenSelected = true;
            }
        })
        setIsStoreInPartner(repair.history[repair.history.length - 1].locationType === "EXTERNAL_PARTNER");
    }, [partners]);

    if (isStoreInPartner) {
        return;
    }

    const transferRepairToPartner = async () => {
        try {
            await repairTransferToPartner(repair.id, selectedPartner.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Something went wrong while creating new order"),
                life: 3000,
                position: "bottom"
            });
            return
        }

        setIsModalVisible(false)
        reloadRepair()
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair updated successfully'),
            life: 3000
        });
    }

    const modalFooterContent = (
        <div>
            <Button label={t("Cancel")} icon="pi pi-times" onClick={() => setIsModalVisible(false)}
                    className="p-button-text"/>
            <Button label={t("Complete")} icon="pi pi-check" onClick={transferRepairToPartner} autoFocus/>
        </div>
    );

    const getPartnerOptions = () => {
        if (!partners) return [];
        let partnerOptions = [];
        partners.map((partner) => {
            const disabled = partner.id === repair.history[repair.history.length - 1].location.id
            partnerOptions.push({
                "partnerDesc": `${partner.alias} (${partner.address})`,
                "id": partner.id,
                "disabled": disabled,
            })
        })
        return partnerOptions
    }

    return <>
        <button className={`transfer-to-partner-button w-full p-5 rounded-md text-white flex ` +
            `${stores && stores.length > 1 ? "flex-col" : "justify-center"} items-center gap-3`}
                onClick={() => {
                    setIsModalVisible(true)
                }}
        >
            <PartnerIcon/>
            {t("Transfer to External Partner")}
        </button>
        <Dialog header={t("Transfer repair to External Partner")} visible={isModalVisible} style={{width: '50vw'}}
                onHide={() => {
                    if (!isModalVisible) return;
                    setIsModalVisible(false);
                }} footer={modalFooterContent}>
            <FormControl label={t("External Partner")}>
                <Dropdown options={getPartnerOptions()} optionLabel="partnerDesc" value={selectedPartner}
                          onChange={(s) => {
                              setSelectedPartner(s.value)
                          }} valueTemplate={(s) => {
                    if (!s) return "";
                    return s.partnerDesc
                }}/>
            </FormControl>
            <FormControl label={t("Comments")}>
                <InputTextarea value={transferComments} className={"w-full"}
                               onChange={(e) => setTransferComments(e.target.value)}
                               rows={5} cols={30}/>
            </FormControl>
        </Dialog></>
}

export default RepairTransferToPartner
